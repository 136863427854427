<template>
  <Box>
    <!-- container -->

    <SavingDialog v-show="showSavingDialog"   @close="closeSavingDialog" />

    <ErrorRow :error="error" />
    <SuccessRow :message="message" />

    <Row>
      <Column :showBorder="true" :width="15">
        <Box>

          <TitleRow v-if="showButtons" align="left" :subtitle="true">
            Details
          </TitleRow>

          <div class="row">
            <div class="rc-box-col-7">
              <div class="container">
                <KeyValue title="first" placeholder="First Name" :defaultValue="user.first()" :disabled="disableAll"
                  v-on:value="setUserValue" />
              </div>
            </div>

            <div class="rc-box-col-8">
              <div class="container">
                <KeyValue title="last" placeholder="Last Name" :defaultValue="user.last()" :disabled="disableAll"
                  v-on:value="setUserValue" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="rc-box-col-7">
              <div class="container">
                <KeyEmail title="email" placeholder="Email" :defaultValue="user.email()" :disabled="disableAll"
                  v-on:value="setUserValue" />
              </div>
            </div>
            <div class="rc-box-col-8">
              <div class="container">
                <KeyPhone title="phone" placeholder="Phone number (AAA-BBB-CCCC)" :defaultValue="user.phone()"
                  :disabled="disableAll" v-on:value="setUserValue" />
              </div>
            </div>
          </div>

          <SpacerRow />

          <TitleRow :subtitle="true" align="left"> Drivers License </TitleRow>
          <Row>
            <Column :width="15">
              <Box>
                <KeyList :title="UserFields.LICENSE_CLASS" placeholder="Class" :defaultValue="licenseClass"
                  :options="licenseClassNames"
                  hint="Helps drivers know what vehicle and trailer combo they are allowed to drive"
                  v-on:value="setUserValue" />
              </Box>
              <Spacer />
              <Box>
                <KeyDate :title="UserFields.LICENSE_EXPIRY" placeholder="Expires" :defaultValue="decode(licenseExpiry)"
                  hind="We'll notify you when the license is about to expire to help with compliance."
                  v-on:value="setUserValue" />
              </Box>
            </Column>
          </Row>

          <SpacerRow />

          <TitleRow align="left" :subtitle="true">
            Employee ID and Role
          </TitleRow>

          <KeyValue title="employmentId" placeholder="Employment Id" :defaultValue="employee.employmentId()"
            :disabled="disableAll" hint="Employee ID is optional unless your organization shares tablets."
            v-on:value="setEmployeeValue" />

          <SpacerRow />

          <KeyList title="UserRoleMap" placeholder="Role" :defaultValue="role()" :disabled="disableAll" :options="roles"
            hint="Every user must have a role, the driver role is the simplest role and lets them use only the app. The manager and owner roles will give them access to the portal as well as the app."
            v-on:value="setEmployeeArrayValue" />

          <SpacerRow />

          <TitleRow align="left" :subtitle="true">
            Address
          </TitleRow>
          <ValueRow align="left">
            Please set your users home address, this value is used by the app when the Home button is pressed
          </ValueRow>
          <AddressRow :addressData="userData" :showTitle="false" v-on:value="setUserValue" />

        </Box> <!-- container 2-->
      </Column> <!-- rc-col-9 -->

    </Row> <!-- row -->


    <SaveRow v-if="showButtons" :left="4" :right="4" :disabled="false" v-on:value="save" />


  </Box> <!-- container -->
</template>

<script>
import { mapGetters } from "vuex";

import Catelog from "@/domain/session/CanadianEnglish.js";
import Utils from "@/views/portal/employees/Utils.js";
import ConstUtils from "@/utils/ConstUtils.js";
import ContentUtils from "@/utils/ContentUtils.js";

import Box from '@/portals/shared/library/box/Box.vue';
import Row from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';

import ValueRow from '@/portals/shared/library/value/ValueRow.vue';
import SpacerRow from '@/portals/shared/library/spacer/SpacerRow.vue';
import Spacer from '@/portals/shared/library/spacer/Spacer.vue';
import TitleRow from '@/portals/shared/library/title/TitleRow.vue';

import KeyList from "@/components/input/KeyList.vue";
import KeyValue from "@/components/input/KeyValue.vue";
import KeyEmail from "@/components/input/KeyEmail.vue";
import KeyPhone from "@/components/input/KeyPhone.vue";
import KeyDate from '@/components/input/KeyDate.vue';

import AddressRow from "@/components/input/address/AddressRow.vue";
import SaveRow from "@/components/row/SaveRow.vue";
import SuccessRow from "@/components/row/SuccessRow.vue";
import ErrorRow from "@/components/row/ErrorRow.vue";
import SavingDialog from "@/portals/shared/views/saving/SavingDialog.vue";

import User from '@/domain/model/user/User.js';

export default {
  name: "employee-edit",
  components: {
    KeyValue, SavingDialog,
    KeyEmail,
    KeyList,
    KeyDate,
    KeyPhone,
    SuccessRow,
    ErrorRow,
    TitleRow,
    SpacerRow, Spacer,
    SaveRow,
    AddressRow,
    Box, Column, Row,
    ValueRow
  },
  props: {
    isAdmin: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: true },
  },
  data() {
    return {
      id: this.$route.params["id"],
      disableAll: false,
      isSaving: false,
      error: null,
      message: null,
      user_kvps: {},
      employee_kvps: {},
      UserFields: User.FIELDS,
      catelog: Catelog.FIELDS,
      LEFT_PADDING: 3,
      RIGHT_PADDING: 3,
      licenseClass: null,
      licenseExpiry: null,
      showSavingDialog: false,
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters([
      "auth_user",
      "auth_client",
      "auth_connected",
      "employees_found",
      "users_store",
      "roles_found",
      "domain",
    ]),
    roles: function () {
      return ContentUtils.ROLES();
    },
    userData: function () {
      return this.user.data;
    },
    user: function () {
      return this.employee.user().find();
    },
    employeeData: function () {
      return this.employee.data;
    },
    employee: function () {
      return this.domain.employees().findById(this.id);
    },
    licenseClassNames: function () {
      return ContentUtils.LICENSES();
    },
  },
  mounted() {
    if (!this.auth_connected) {
      this.$router.replace({
        name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN,
      });
    } else {
      this.employee_kvps[ConstUtils.FIELDS.ID] = this.employee.id()
      this.user_kvps[ConstUtils.FIELDS.ID] = this.user.id();

      this.licenseClass = this.user.licenseClass();
      this.licenseExpiry = this.user.licenseExpiry();
    }
  },

  methods: {
    decode: function (value) {
      return ContentUtils.unescape(value);
    },
    setUserValue: function (kvp) {
      this.error = null;
      this.message = null;
      if (kvp.key == "street") {
        kvp.key = "address"
      }
      ContentUtils.set(this.user_kvps, kvp, this.domain.logger());
      this.isSaveDisabled = !ContentUtils.has([this.user_kvps, this.employee_kvps])
    },
    setEmployeeValue: function (kvp) {
      this.error = null;
      this.message = null;
      ContentUtils.set(this.employee_kvps, kvp, this.domain.logger());
      this.isSaveDisabled = !ContentUtils.has([this.user_kvps, this.employee_kvps])
    },
    setEmployeeArrayValue: function (kvp) {
      this.error = null;
      this.message = null;
      kvp.value = [kvp.value];
      ContentUtils.set(this.employee_kvps, kvp, this.domain.logger());
      this.isSaveDisabled = !ContentUtils.has([this.user_kvps, this.employee_kvps])
    },

    rid: function (value) {
      if (value) {
        return value[ConstUtils.FIELDS.ID];
      }
      return null;
    },

    phoneNumber: function (user) {
      return Utils.phoneNumber(user);
    },

    address: function (user) {
      return Utils.address(user);
    },

    first: function (user) {
      return Utils.first(user);
    },

    last: function (user) {
      return Utils.last(user);
    },

    email: function (user) {
      return Utils.email(user);
    },

    country: function (user) {
      return Utils.country(user);
    },

    postal: function (user) {
      return Utils.postal(user);
    },

    province: function (user) {
      return Utils.province(user);
    },

    city: function (user) {
      return Utils.city(user);
    },

    role: function () {
      return this.employee.roles().find().first().id();
    },

    state: function (employee) {
      if (employee) {
        return employee.state;
      }
      return "Unknown";
    },

    employmentId: function (employee) {
      if (employee) {
        return employee.employmentId;
      }
      return "";
    },

    save: function () {
      this.error = null;
      this.message = null;
      this.disableAll = true;
      this.isSaving = true;
      this.showSavingDialog = true;
      var event = this.domain.events().userSave(this.user_kvps, this.employee_kvps);
      event.send(this.listener);
    },
    
    listener: function (event) {
      this.isSaving = false;
      this.disableAll = false;
      this.showSavingDialog = false;
      if (event.error()) {
        this.error = event.error();
      } else {
        window.scrollTo(0, 0);
        this.message = "User changes have been succuessfully saved!";
        this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.EMPLOYEE.LIST });
      }
    },
    
    saving: function() {
      this.showSavingDialog = true;
    },
    
    closeSavingDialog: function() {
     this.showSavingDialog = false;  
    },
  },
};
</script>